import { ApolloQueryResult } from '@apollo/client';
import { Observable, OperatorFunction, catchError, filter, map, of, tap } from 'rxjs';
import { LoadProgressService } from '../services/load-progress.service';
import { stripTypename } from '@apollo/client/utilities';

export function errorHandler(err: unknown): Observable<null> {
  console.error('Error handler:', err);
  // Handle the error, maybe return a default value or another observable, etc.
  return of(null);
}

export function queryResponseHandler<T>(inputData: ApolloQueryResult<T>): T {
  if (!inputData.data) {
    throw new Error('No data received from query');
  }
  return stripTypename(inputData.data) as T;
}

export function queryMutationHandler(inputData: any): any {
  if (!inputData.data) {
    throw new Error('No data received from mutation');
  }
  return stripTypename(inputData.data);
}

export function filterDataLoadingErroRxHandler<T>(
  loaderService: LoadProgressService
): OperatorFunction<any, T | null> {
  return (source: Observable<any>): Observable<T | null> => {
    return source.pipe(
      tap(({ loading }) => loaderService.setLoading(loading)),
      filter(({ data }) => {
        loaderService.setLoading(false);
        if (!data) {
          console.warn('No data received from query');
          return false;
        }
        return true;
      }),
      map(({ data }) => stripTypename(data)),
      catchError((error) => {
        loaderService.setLoading(false);
        return errorHandler(error);
      })
    );
  };
}
